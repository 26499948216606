var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-4 mb-2",
    staticStyle: {
      "height": "600px",
      "overflow": "auto",
      "border": "1px solid #eee"
    }
  }, [_c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "rftEditProductEditSpecForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.form,
      "label-width": "250px"
    }
  }, [_c('el-collapse', {
    model: {
      value: _vm.collapActiveNames,
      callback: function ($$v) {
        _vm.collapActiveNames = $$v;
      },
      expression: "collapActiveNames"
    }
  }, [_vm._l(_vm.availableSpecs, function (categories, index) {
    return [_c('el-collapse-item', {
      key: index,
      attrs: {
        "name": categories.id
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('el-badge', {
      staticClass: "categorycount",
      attrs: {
        "value": categories.specifications.length
      }
    }, [_c('strong', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(categories.category))])])], 1), _vm._l(categories.specifications, function (spec, i) {
      return [_c('div', {
        key: i
      }, [_c('el-form-item', {
        attrs: {
          "prop": ("" + (spec.id))
        }
      }, [_c('span', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v(_vm._s(spec.name) + " "), spec.description ? _c('el-tooltip', {
        attrs: {
          "content": spec.description,
          "placement": "top"
        }
      }, [_c('i', {
        staticClass: "el-tooltip el-icon-question"
      })]) : _vm._e()], 1), _c('el-input', {
        attrs: {
          "placeholder": spec.description,
          "disabled": !_vm.editable && !_vm.isNewAnswer
        },
        model: {
          value: _vm.form[("" + (spec.id))],
          callback: function ($$v) {
            _vm.$set(_vm.form, ("" + (spec.id)), $$v);
          },
          expression: "form[`${spec.id}`]"
        }
      })], 1)], 1)];
    })], 2)];
  })], 2), _c('div', {
    staticClass: "mt-4"
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }